import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSelectedTenses, saveSelectedTenses } from "./LocalStorage";
import { Tense, getAllTenses } from "./tenses";
import { Helmet } from "react-helmet";

interface SelectableTense {
  tense: Tense
  selected: boolean
}

function getSelectableTenses(): SelectableTense[] {
  const tenses = getAllTenses();
  const selectedTenses = getSelectedTenses();
  return tenses.map(tense => ({ tense: tense, selected: selectedTenses.includes(tense.id) }));
}

function TenseSelectionPage() {
  const [tenses, setTenses] = useState<SelectableTense[]>(getSelectableTenses())
  const navigate = useNavigate()

  const selectAllTenses = () => {
    const newVerbs = tenses.map(tense => ({...tense, selected: true}))
    setTenses(newVerbs)
  }

  const unselectAllTenses = () => {
    const newVerbs = tenses.map(tense => ({...tense, selected: false}))
    setTenses(newVerbs)
  }

  const onSaveClick = () => {
    const selectedTenses = tenses.filter(t => t.selected).map(t => t.tense.id)
    saveSelectedTenses(selectedTenses)
    navigate("/conjugations")
  }
  
  const onBackClick = () => {
    navigate("/conjugations")
  }

  const tenseSelectionChangeHandler = (updatedTense: SelectableTense) => {
    const newTenses = tenses.map(tense =>
      (tense.tense.id === updatedTense.tense.id) ? {...tense, selected: !tense.selected} : tense
    )
    setTenses(newTenses)
  }

  const selectedTenses = tenses.filter(t => t.selected)

  return (
    <>
    <Helmet>
      <title>Select Tenses for Spanish Conjugation Practice</title>
      <meta name="keywords" content="choose tenses, Spanish verb tenses, Spanish conjugation tenses, past tense, present tense, future tense, Spanish grammar, verb tense practice"/>
      <meta name="description" content="Choose the Spanish verb tenses you want to practice. Focus on the present, past, or future tenses to improve your conjugation skills."/>
    </Helmet>
    
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title">Choose tenses</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field is-grouped">
              <p className="control">
                <button className="button is-light is-small" onClick={selectAllTenses}>Select all {tenses.length} tenses</button>
              </p>
              <p>
                <button className="button is-light is-small" onClick={unselectAllTenses}>Unselect all tenses</button>
              </p>
            </div>
          </div>
        </div>
        <div style={{marginBottom: "72px"}}>
          {tenses.map(t => 
            <div className="columns">
              <div className="column">
                <TenseItem key={t.tense.id} tense={t} onSelectionChange={tenseSelectionChangeHandler}></TenseItem>
              </div>
            </div>
          )}
          <div className="columns">
            <div className="column">
              <div className="cell py-4 px-3 has-radius-normal has-text-centered">
                <div className="is-size-6">More tenses soon...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <nav className="navbar is-fixed-bottom has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-menu is-active navbar-menu-custom">
        <div className="navbar-end">
          <div className="navbar-item navbar-item-custom">
            <div className="columns is-mobile is-vcentered">
              <div className="column">
                <p>Selected tenses: {selectedTenses.length}</p>
              </div>
              <div className="column is-narrow">
                <div className="buttons">
                  <button className="button is-primary is-light" onClick={onBackClick}>Back</button>
                  <button className="button is-primary" onClick={onSaveClick} disabled={selectedTenses.length === 0}>Save</button>    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    </>
  );
}

interface Props {
  tense: SelectableTense
  onSelectionChange: any
}

function TenseItem(props: Props) {
  const tense = props.tense

  const toggleSelection = () => props.onSelectionChange(tense)

  const backgroundColorClass = tense.selected ? "has-background-selected" : "has-background-unselected"
  const classes = `cell py-4 px-3 has-radius-normal has-text-centered ${backgroundColorClass}`

  return (
    <div className={classes} onClick={toggleSelection} style={{cursor: "pointer", outline: "none"}}>
      <div className="is-size-6">{props.tense.tense.title}</div>
    </div>
  )
}

export default TenseSelectionPage;
