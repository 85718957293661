export function getAllTenses(): Tense[] {
  return [
    { id: 'presente', title: 'Presente' },
    { id: 'preterito', title: 'Pretérito Indefinido' },
    { id: 'futuro', title: 'Futuro Simple' },
    // { id: 'preterito-perfecto', title: 'Pretérito Perfecto' },
    // { id: 'preterito-imperfecto', title: 'Pretérito Imperfecto' },
  ];
}

export interface Tense {
  id: string
  title: string
}
