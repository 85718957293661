import styled from 'styled-components';
import { BoldifyText } from './BoldifyText';

interface Props {
  phrase: string;
  visible?: boolean;
  formal?: boolean;
  verb?: string;
  tense?: string;
}

const PhraseBoxStyled = styled.div`
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.2;
`;

export function PhraseBox(props: Props) {
  const phrase = props.phrase;
  const visible = props.visible ?? true;
  const formal = props.formal ?? false;
  const verb = props.verb;
  const tense = props.tense;

  return (
    <PhraseBoxStyled>
      {
        visible
        ?
        <>
          <BoldifyText text={phrase}/>
          { formal && <div><span className="tag is-warning" style={{backgroundColor: "hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-60-l), 1)"}}>formal</span></div> }
        </>
        :
        "___"
      }
      {
        verb && tense &&
        <div style={{fontSize: "0.8rem", marginTop: "0.5rem"}}>
          <span style={{fontStyle: "italic"}}>{verb} &ndash; {tense}</span>
        </div>
      }
    </PhraseBoxStyled>
  );
}
