import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSelectedVerbs, saveSelectedVerbs } from "./LocalStorage";
import { Verb, getAllVerbs } from "./verbs";
import { Helmet } from "react-helmet";

function getSelectableVerbs(): SelectableVerb[] {
  const verbs = getAllVerbs();
  const selectedVerbs = getSelectedVerbs()
  return verbs.map(verb => ({ verb: verb, selected: selectedVerbs.includes(verb.spa) }))
}

function VerbSelectionPage() {
  const [verbs, setVerbs] = useState<SelectableVerb[]>(getSelectableVerbs())

  const navigate = useNavigate();

  const verbSelectionChangeHandler = (updatedVerb: SelectableVerb) => {
    const newVerbs = verbs.map(verb =>
      (verb.verb.spa === updatedVerb.verb.spa) ? {...verb, selected: !verb.selected} : verb
    )
    setVerbs(newVerbs)
  }

  const selectAllVerbs = () => {
    const newVerbs = verbs.map(verb => ({...verb, selected: true}))
    setVerbs(newVerbs)
  }

  const unselectAllVerbs = () => {
    const newVerbs = verbs.map(verb => ({...verb, selected: false}))
    setVerbs(newVerbs)
  }

  const onSaveClick = () => {
    const selectedVerbs = verbs.filter(v => v.selected).map(v => v.verb.spa)
    saveSelectedVerbs(selectedVerbs)
    navigate("/conjugations")
  }

  const onBackClick = () => {
    navigate("/conjugations")
  }

  const selectedVerbs = verbs.filter(v => v.selected)

  return (
    <>
    <Helmet>
      <title>Choose Spanish Verbs for Conjugation Practice</title>
      <meta name="keywords" content="choose Spanish verbs, verb selection, Spanish verb list, top 100 Spanish verbs, verb conjugation training, learning Spanish verbs, Spanish verb practice"/>
      <meta name="description" content="Select the most common Spanish verbs to practice conjugations. Train with the top 100 verbs to improve your language skills."/>
    </Helmet>

    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title">Choose verbs</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field is-grouped">
              <p className="control">
                <button className="button is-light is-small" onClick={selectAllVerbs}>Select all {verbs.length} verbs</button>
              </p>
              <p>
                <button className="button is-light is-small" onClick={unselectAllVerbs}>Unselect all verbs</button>
              </p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="grid" style={{marginBottom: "72px"}}>
              {verbs.map(v => <VerbItem key={v.verb.spa} verb={v} onSelectionChange={verbSelectionChangeHandler}></VerbItem>)}
            </div>
          </div>
        </div>
      </div>
    </section>

    <nav className="navbar is-fixed-bottom has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-menu is-active navbar-menu-custom">
        <div className="navbar-end">
          <div className="navbar-item navbar-item-custom">
            <div className="columns is-mobile is-vcentered">
              <div className="column">
                <p>Selected verbs: {selectedVerbs.length}</p>
              </div>
              <div className="column is-narrow">
                <div className="buttons">
                  <button className="button is-primary is-light" onClick={onBackClick}>Back</button>
                  <button className="button is-primary" onClick={onSaveClick} disabled={selectedVerbs.length === 0}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    </>
  );
}

interface SelectableVerb {
  verb: Verb
  selected: boolean
}

interface Props {
  verb: SelectableVerb
  onSelectionChange: any
}

function VerbItem(props: Props) {
  const verb = props.verb

  const toggleSelection = () => props.onSelectionChange(verb)

  const backgroundColorClass = verb.selected ? "has-background-selected" : "has-background-unselected"
  const classes = `cell py-2 px-3 has-radius-normal has-text-centered ${backgroundColorClass} foo`

  return (
    <div className={classes} onClick={toggleSelection} style={{cursor: "pointer", outline: "none"}}>
      <div className="is-size-6">{props.verb.verb.spa}</div>
      <div className="is-size-7">{props.verb.verb.eng}</div>
    </div>
  )
}

export default VerbSelectionPage;
