import { httpsCallable } from "firebase/functions";
import { Phrase } from "./Types";
import { functions } from "./firebase-config";

export const getPhrases = async (verbs: string[], tenses: string[]) => {
  const getPhrases = httpsCallable<any, Phrase[]>(functions, 'getPhrases');
  return getPhrases({ 'verbs': verbs, 'tenses': tenses})
    .then((result) => result.data)
    .catch((error) => { throw error; });
}
