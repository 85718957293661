export function saveSelectedVerbs(verbs: string[]) {
  localStorage.setItem("verbs", JSON.stringify(verbs))
}

export function getSelectedVerbs(): string[] {
  const verbsJson = localStorage.getItem("verbs")
  return verbsJson ? JSON.parse(verbsJson) : []
}

export function saveSelectedTenses(tenses: string[]) {
  localStorage.setItem("tenses", JSON.stringify(tenses))
}

export function getSelectedTenses(): string[] {
  const tensesJson = localStorage.getItem("tenses")
  return tensesJson ? JSON.parse(tensesJson) : []
}

export function getTranslationDirectionOrDefault(def: TranslationDirection): TranslationDirection {
  const direction = getTranslationDirection();
  if (direction) {
    return direction;
  } else {
    saveTranslationDirection(def);
    return def;
  }
}

function getTranslationDirection(): TranslationDirection | undefined {
  const direction = localStorage.getItem("direction");
  return TranslationDirection[direction as keyof typeof TranslationDirection];
}

export function saveTranslationDirection(dir: TranslationDirection) {
  localStorage.setItem("direction", dir)
}

export function saveShowHint(value: boolean) {
  localStorage.setItem("showHint", value.toString());
}

export function getShowHint(): boolean {
  const showHintJson = localStorage.getItem("showHint");
  return showHintJson === "true";
}

export enum TranslationDirection {
  FROM_SPANISH = "FROM_SPANISH",
  TO_SPANISH = "TO_SPANISH",
}
