import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function LangingPage() {
  const navigate = useNavigate();
  
  const goHome = () => navigate("/conjugations")

  return (
    <>
      <Helmet>
        <title>Spanish Verb Conjugation Trainer - Learn and Practice Spanish Verbs</title>
        <meta name="keywords" content="Spanish verbs, conjugation practice, learn Spanish, Spanish grammar, Spanish verb conjugations, language learning, conjugation trainer"/>
        <meta name="description" content="Learn and practice Spanish verb conjugations with our interactive web app. Choose verbs and tenses to train, translate sentences, and improve your Spanish grammar skills."/>
      </Helmet>
      
      <section className="hero is-medium is-bold is-light">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-size-1">
              Master Spanish Verb Conjugations
            </h1>
            <h2 className="subtitle is-size-4" style={{marginTop: "1rem"}}>
              The simple and proven way to learn and practice Spanish verbs
            </h2>
            <a className="button is-primary is-large" onClick={goHome}>Get Started &ndash; It's Free</a>
          </div>
        </div>
      </section>

      <section id="features" className="section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-one-quarter">
              <div className="card card-landing">
                <div className="card-content has-text-centered">
                  <p className="title is-size-4">100 Most Common Verbs</p>
                  <p className="subtitle is-size-6">Choose from the most frequently used verbs in Spanish</p>
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="card card-landing">
                <div className="card-content has-text-centered">
                  <p className="title is-size-4">Multiple Tenses</p>
                  <p className="subtitle is-size-6">Practice conjugations in different tenses, choose those tenses you need to train more</p>
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="card card-landing">
                <div className="card-content has-text-centered">
                  <p className="title is-size-4">Learn with Context</p>
                  <p className="subtitle is-size-6">Practice verb forms not as separate words but as parts of the full sentences</p>
                </div>
              </div>
            </div>
            <div className="column is-one-quarter">
              <div className="card card-landing">
                <div className="card-content has-text-centered">
                  <p className="title is-size-4">Bilingual Training</p>
                  <p className="subtitle is-size-6">Train translations in both directions: English to Spanish and vice versa</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-text-centered" style={{backgroundColor: "#fbfcd5"}}>
        <div className="container">
          <h3 className="title is-size-3">Start Practicing Today</h3>
          <p className="subtitle is-size-5">Become proficient in Spanish verb conjugations!</p>
          <a className="button is-primary is-large" onClick={goHome}>Try the App</a>
        </div>
      </section>

      <footer className="footer">
        <div className="content has-text-centered">
          <p>&copy; 2024 SpanishDaily.online</p>
        </div>
      </footer>
    </>
  )
}

export default LangingPage;
