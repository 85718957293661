import { Helmet } from 'react-helmet';

function NotFoundPage() {
  return (
    <section className="section">
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>

      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title">Page Not Found</h1>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <p>Go to <a href="/conjugations" style={{textDecoration: "underline"}}>Verbs Conjugation</a> page</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFoundPage;
