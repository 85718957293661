import { ChangeEvent, useEffect, useState } from 'react';
import { Phrase } from './Types';
import { useNavigate } from 'react-router-dom';
import { TranslationDirection, getSelectedTenses, getSelectedVerbs, getShowHint, getTranslationDirectionOrDefault, saveSelectedTenses, saveSelectedVerbs, saveShowHint, saveTranslationDirection } from './LocalStorage';
import { getAllTenses } from './tenses';
import { getAllVerbs } from './verbs';
import { getPhrases } from './PhrasesGateway';
import { PhraseBox } from './PhraseBox';
import { Helmet } from 'react-helmet';

function VerbConjugationsPage() {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [translationVisible, setTranslationVisible] = useState(false);
  const [phrases, setPhrases] = useState<Phrase[]>([]);
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [cardVisible, setCardVisible] = useState(false);

  const defaultDirection = getTranslationDirectionOrDefault(TranslationDirection.FROM_SPANISH);
  const [settingsDirection, setSettingsDirection] = useState(defaultDirection);
  const [cardDirection, setCardDirection] = useState(defaultDirection);

  const [showHintCheckboxEnabled, setShowHintCheckboxEnabled] = useState(cardDirection === TranslationDirection.TO_SPANISH);
  const [showHint, setShowHint] = useState(showHintCheckboxEnabled && getShowHint());

  const navigate = useNavigate();

  let verbs = getSelectedVerbs();
  if (verbs.length === 0) {
    // if no verbs selected get all verbs and save
    verbs = getAllVerbs().map(v => v.spa);
    saveSelectedVerbs(verbs);
  }

  let tenses = getSelectedTenses();
  if (tenses.length === 0) {
    // if no tenses selected get all tenses and save
    tenses = getAllTenses().map(t => t.id);
    saveSelectedTenses(tenses);
  }

  useEffect(() => {
    hideCard();
    loadNewPhrases();
    // setPhrases([
    //   {
    //     spa: "Yo soy estudiante",
    //     eng: "I am a student",
    //   },
    //   {
    //     spa: "Tú eres guapa",
    //     eng: "You are pretty",
    //   },
    // ])
  }, []);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.code === 'Space' && !e.repeat) {
        if (!loading && !errorMessage) {
          onNextClick();
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [phraseIndex, translationVisible, loading, errorMessage]);

  const onNextClick = () => {
    if (translationVisible) {
      hideCard();
      let nextPhraseIndex = phraseIndex + 1;
      if (nextPhraseIndex < phrases.length) {
        setTimeout(() => {
          setPhraseIndex(nextPhraseIndex);
          setTranslationVisible(false);
          showCard();
        }, 200);
      } else {
        loadNewPhrases();
      }
    } else {
      setTranslationVisible(true);
    }
  }

  const loadNewPhrases = () => {
    setLoading(true);
    getPhrases(verbs, tenses)
      .then(phrases => {
        setLoading(false);
        setErrorMessage(null);
        setPhrases(phrases);
        setPhraseIndex(0);
        setTranslationVisible(false);
        setTimeout(() => showCard(), 200);
      }, error => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }

  const hideCard = () => setCardVisible(false);

  const showCard = () => setCardVisible(true);

  const onChangeVerbsClick = () => {
    navigate("/verbs")
  }

  const onChangeTensesClick = () => {
    navigate("/tenses")
  }

  const switchCardDirection = (newDirection: TranslationDirection) => {
    saveTranslationDirection(newDirection);
    setSettingsDirection(newDirection);
    setShowHintCheckboxEnabled(newDirection === TranslationDirection.TO_SPANISH);
    hideCard();
    setTimeout(() => {
      setCardDirection(newDirection);
      if (newDirection === TranslationDirection.FROM_SPANISH) {
        setShowHint(false);
      } else {
        setShowHint(getShowHint());
      }
      showCard();
    }, 200);
  }

  const handleShowHintCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newShowHint = event.target.checked;
    saveShowHint(newShowHint)
    setShowHint(newShowHint);
  }

  let eng = "", spa = "", formal = false, verb = "", tense = "";
  if (phrases.length > 0) {
    eng = phrases[phraseIndex].eng;
    spa = phrases[phraseIndex].spa;
    formal = spa.toLowerCase().includes("usted")
    verb = phrases[phraseIndex].verb;
    tense = phrases[phraseIndex].tense;
  }

  return (
    <section className="section">
      <Helmet>
        <title>Practice Spanish Verb Conjugations - Translate Sentences</title>
        <meta name="keywords" content="Spanish conjugation practice, Spanish sentence translation, translate to Spanish, translate to English, Spanish verb trainer, verb conjugation exercises, Spanish learning"/>
        <meta name="description" content="Practice Spanish verb conjugations by translating sentences. Train with selected verbs and tenses, and check your translations from English to Spanish or vice versa."/>
      </Helmet>

      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title">Verbs Conjugations</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column" style={{fontSize: '0.8rem', fontStyle: 'italic', paddingTop: 0, paddingBottom: 0}}>
            <p className="card-switch-hint-touch">Tap on the card to show translation/next card</p>
            <p className="card-switch-hint-mouse">Click on the card or press Space to show translation/next card</p>
          </div>
        </div>

        {
          loading
          ?
          <div className="columns">
            <div className="column has-text-centered">
              <button className="button is-ghost is-large is-loading"></button>
            </div>
          </div>
          :
          (
            errorMessage
            ?
            <div className="columns">
              <div className="column has-text-centered">
                <p>Error occurred: {errorMessage}</p>
                <button className="button" onClick={loadNewPhrases}>Retry</button>
              </div>
            </div>
            :
            <>
              <div className="columns">
                <div className="column">
                  <div className={`card unselectable translation-card ${cardVisible ? "visible-200" : "hidden-200"}`} onClick={onNextClick}>
                    <div style={{backgroundColor: "hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1)", borderTopLeftRadius: "var(--bulma-card-radius)", borderTopRightRadius: "var(--bulma-card-radius)"}}>
                      {
                        cardDirection === TranslationDirection.FROM_SPANISH
                        ?
                        <PhraseBox phrase={spa}></PhraseBox>
                        :
                        <PhraseBox phrase={eng} formal={formal}></PhraseBox>
                      }
                    </div>
                    <div style={{backgroundColor: "hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l), 1)", borderBottomLeftRadius: "var(--bulma-card-radius)", borderBottomRightRadius: "var(--bulma-card-radius)"}}>
                      {
                        cardDirection === TranslationDirection.FROM_SPANISH
                        ?
                        <PhraseBox phrase={eng} visible={translationVisible} formal={formal}></PhraseBox>
                        :
                        (
                          showHint
                          ?
                          <PhraseBox phrase={spa} visible={translationVisible} verb={verb} tense={tense}></PhraseBox>
                          :
                          <PhraseBox phrase={spa} visible={translationVisible}></PhraseBox>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column has-text-left">
                  <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <button className="button is-small is-light" aria-haspopup="true" aria-controls="dropdown-menu4">
                        <span className="icon">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="icon is-small">
                          <i className="fas fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu4" role="menu" style={{minWidth: "14rem"}}>
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <div style={{marginBottom: "0.4rem"}}><strong>Translation direction</strong></div>
                          <div style={{marginBottom: "0.4rem"}}>
                            <label className="radio">
                              <input
                                type="radio"
                                name="direction"
                                checked={settingsDirection === TranslationDirection.FROM_SPANISH}
                                onChange={() => switchCardDirection(TranslationDirection.FROM_SPANISH)}
                              />&nbsp;Spanish → English
                            </label>
                          </div>
                          <div style={{marginBottom: "0.4rem"}}>
                            <label className="radio">
                              <input
                                type="radio"
                                name="direction"
                                checked={settingsDirection === TranslationDirection.TO_SPANISH}
                                onChange={() => switchCardDirection(TranslationDirection.TO_SPANISH)}
                              />&nbsp;English → Spanish
                            </label>
                          </div>
                          <div>
                            <label className="checkbox" style={{marginLeft: "1rem"}}>
                              <p><input type="checkbox" onChange={handleShowHintCheckboxChange} disabled={!showHintCheckboxEnabled} checked={showHint}/>&nbsp;Show hint on translation</p>
                              <p style={{marginLeft: "1rem"}}>(verb and tense)</p>
                            </label>
                          </div>
                        </div>
                        <div className="dropdown-divider"/>
                        <div className="dropdown-item">
                          <div className="columns is-mobile">
                            <div className="column" style={{paddingRight: 0}}>
                              <p>Selected verbs: {verbs.length}</p>
                            </div>
                            <div className="column has-text-right is-narrow">
                              <p><a onClick={onChangeVerbsClick} style={{textDecoration: "underline"}}>change</a></p>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-divider"/>
                        <div className="dropdown-item">
                          <div className="columns is-mobile">
                            <div className="column" style={{paddingRight: 0}}>
                              <p>Selected tenses: {tenses.length}</p>
                            </div>
                            <div className="column has-text-right is-narrow">
                              <p><a onClick={onChangeTensesClick} style={{textDecoration: "underline"}}>change</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column has-text-right">
                  <button className="button is-light is-small" onClick={() => navigate("/contacts")}>
                    <span className="icon">
                      <i className="fas fa-envelope"></i>
                    </span>
                    <span>Contact us</span>
                  </button>
                </div>
              </div>
            </>
          )
        }
      </div>
    </section>
  );
}

export default VerbConjugationsPage;
