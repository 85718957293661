export function getAllVerbs(): Verb[] {
  return [
    { spa: 'ser', eng: 'to be' }, //
    { spa: 'estar', eng: 'to be' }, //
    { spa: 'tener', eng: 'to have' }, //
    { spa: 'hacer', eng: 'to do' }, //
    { spa: 'decir', eng: 'to say' }, //
    { spa: 'ir', eng: 'to go' }, //
    { spa: 'venir', eng: 'to come' }, //
    { spa: 'ver', eng: 'to see' }, //
    { spa: 'mirar', eng: 'to look at' }, //
    { spa: 'poder', eng: 'to be able to/can' }, //
    { spa: 'querer', eng: 'to want' }, //
    { spa: 'saber', eng: 'to know (information)' }, //
    { spa: 'conocer', eng: 'to know (people, places)' }, //
    { spa: 'poner', eng: 'to put, place, set' }, //
    { spa: 'salir', eng: 'to leave, go out' }, //
    { spa: 'traer', eng: 'to bring' }, //
    { spa: 'pensar', eng: 'to think' }, //
    { spa: 'sentir', eng: 'to feel' }, //
    { spa: 'encontrar', eng: 'to find, encounter' }, //
    { spa: 'buscar', eng: 'to search for, look for'}, //
    { spa: 'llevar', eng: 'to carry, bring' }, //
    { spa: 'llegar', eng: 'to arrive, come' }, //
    { spa: 'empezar', eng: 'to begin, start' }, //
    { spa: 'terminar', eng: 'to finish, end' }, //
    { spa: 'seguir', eng: 'to follow, continue' }, //
    { spa: 'vivir', eng: 'to live' }, //
    { spa: 'trabajar', eng: 'to work' }, //
    { spa: 'estudiar', eng: 'to study' }, //
    { spa: 'aprender', eng: 'to learn' },
    { spa: 'enseñar', eng: 'to teach' },
    { spa: 'entender', eng: 'to understand' }, //
    { spa: 'hablar', eng: 'to speak' }, //
    { spa: 'escuchar', eng: 'to listen' }, //
    { spa: 'oír', eng: 'to hear' }, //
    { spa: 'preguntar', eng: 'to ask' }, //
    { spa: 'responder', eng: 'to respond, answer' },
    { spa: 'escribir', eng: 'to write' }, //
    { spa: 'leer', eng: 'to read' }, //
    { spa: 'comprar', eng: 'to buy, purchase' },
    { spa: 'vender', eng: 'to sell' },
    { spa: 'pagar', eng: 'to pay' }, //
    { spa: 'recibir', eng: 'to receive' }, //
    { spa: 'enviar', eng: 'to send' },
    { spa: 'abrir', eng: 'to open' }, //
    { spa: 'cerrar', eng: 'to close' },
    { spa: 'reconocer', eng: 'to recognize' }, //
    { spa: 'creer', eng: 'to believe' }, //
    { spa: 'imaginar', eng: 'to imagine' },
    { spa: 'recordar', eng: 'to remember, remind' }, //
    { spa: 'olvidar', eng: 'to forget' },
    { spa: 'ayudar', eng: 'to help' }, //
    { spa: 'necesitar', eng: 'to need, require' }, //
    { spa: 'desear', eng: 'to wish, desire, want' },
    { spa: 'amar', eng: 'to love' },
    { spa: 'gustar', eng: 'to please, like' }, // TODO //
    { spa: 'preferir', eng: 'to prefer' },
    { spa: 'elegir', eng: 'to choose, elect' },
    { spa: 'tomar', eng: 'to take, drink' }, //
    { spa: 'beber', eng: 'to drink' },
    { spa: 'comer', eng: 'to eat' },
    { spa: 'cocinar', eng: 'to cook' },
    { spa: 'preparar', eng: 'to prepare' },
    { spa: 'dormir', eng: 'to sleep' },
    { spa: 'despertar', eng: 'to wake up (somebody)' },
    { spa: 'soñar', eng: 'to dream' },
    { spa: 'dar', eng: 'to give' },
    { spa: 'pasar', eng: 'to pass, spend (time), happen' },
    { spa: 'deber', eng: 'must, should, have to' },
    { spa: 'parecer', eng: 'to seem, appear' },
    { spa: 'quedarse', eng: 'to stay, remain' },
    { spa: 'dejar', eng: 'to leave, abandon, to let, allow' },
    { spa: 'llamar', eng: 'to call, to name' },
    { spa: 'volver', eng: 'to return, go back' },
    { spa: 'esperar', eng: 'to wait for, to hope' },
    { spa: 'pedir', eng: 'to request, to ask for' },
    { spa: 'intentar', eng: 'to try, attempt' },
    { spa: 'jugar', eng: 'to play (a game or sport)' },
    { spa: 'utilizar', eng: 'to use, utilize' },
    { spa: 'cambiar', eng: 'to change' },
    { spa: 'correr', eng: 'to run' },
    { spa: 'conseguir', eng: 'to get, obtain, achieve' },
    { spa: 'producir', eng: 'to produce' },
    { spa: 'contar', eng: 'to count, tell' },
    { spa: 'entrar', eng: 'to enter, go in, come in' },
    { spa: 'sacar', eng: 'to take out' },
    { spa: 'crear', eng: 'to create, to make' },
    { spa: 'convertir', eng: 'to convert, turn' },
    { spa: 'ganar', eng: 'to win, gain, earn' },
    { spa: 'nacer', eng: 'to be born' },
    { spa: 'morir', eng: 'to die' },
    { spa: 'perder', eng: 'to lose' },
    { spa: 'caer', eng: 'to fall' },
    { spa: 'partir', eng: 'to divide, to leave' },
    { spa: 'tocar', eng: 'to touch, to play (an instrument)' },
    { spa: 'ofrecer', eng: 'to offer' },
    { spa: 'descubrir', eng: 'to discover' },
    { spa: 'levantar', eng: 'to raise, to lift' },
    { spa: 'aparecer', eng: 'to appear, show up' },
    { spa: 'aceptar', eng: 'to accept, approve, to agree to' },
    { spa: 'mantener', eng: 'to maintain' },

    // tratar
    // existir
    // occurir
    // permitir
    // comenzar
    // servir
    // resultar
    // presentar
    // considerar
    // acabar
    // formar
    // realizar
    // suponer
    // comprender
    // lograr
    // alcanzar
    // dirigir
    // cumplir
  ]
}

export interface Verb {
  spa: string
  eng: string
}
