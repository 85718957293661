import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase-config';

function ContactsPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const maxLength = 1000;

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    let error = false;

    if (name.trim() === "") {
      setNameError("Name is required");
      error = true;
    }

    if (!validateEmail(email)) {
      setEmailError("This email is invalid");
      error = true;
    } else {
      setEmailError("");
    }

    if (message.trim() === "") {
      setMessageError("Message is blank");
      error = true;
    }

    if (!error) {
      setLoading(true);
      addDoc(collection(db, "contactRequests"), {
        name: name,
        email: email,
        message: message,
        createdAt: serverTimestamp(),
      }).then(result => {
        console.log({result});
        setLoading(false);
      }, error => {
        setLoading(false);
        console.log({error});
      });
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    if (nameError && newName.trim() !== "") {
      setNameError("");
    }
    setName(newName);
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    if (emailError && validateEmail(newEmail)) {
      setEmailError("");
    }
    setEmail(newEmail);
  }

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value.slice(0, maxLength); // Ensure it doesn't exceed maxLength
    if (messageError && newMessage.trim() !== "") {
      setMessageError("");
    }
    setMessage(newMessage);
  };

  return (
    <section className="section">
      <Helmet>
        <title>Contact Us</title>
      </Helmet>

      <div className="container">

        <div className="columns">
          <div className="column">
            <h1 className="title">Contact Us</h1>
          </div>
        </div>

        <div className="columns">
          <div className="column" style={{fontSize: '0.8rem', fontStyle: 'italic', paddingTop: 0, paddingBottom: 0}}>
            <p>Share your thoughts, suggestions, or feedback with us</p>
          </div>
        </div>

        <div className="columns">
          <div className="column">

            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label">Name</label>
                <div className="control has-icons-left">
                  <input className={`input ${nameError && "is-danger"}`} type="text" maxLength={100} placeholder="Your name" value={name} onChange={handleNameChange}/>
                  <span className="icon is-small is-left">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                { nameError && <p className="help is-danger">{nameError}</p> }
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left">
                  <input className={`input ${emailError && "is-danger"}`} type="email" maxLength={100} placeholder="your@email.com" value={email} onChange={handleEmailChange}/>
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
                { emailError && <p className="help is-danger">{emailError}</p> }
              </div>
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea className={`textarea ${messageError && "is-danger"}`} placeholder="Your message" maxLength={maxLength} onChange={handleMessageChange} value={message}></textarea>
                </div>
                <p className={`help ${messageError && "is-danger"}`}>{message.length}/{maxLength}</p>
              </div>
              <div className="field is-grouped is-grouped-right">
                <div className="control">
                  <button className={`button is-primary ${loading && "is-loading"}`} type="submit">Submit</button>
                </div>
                <div className="control">
                  <button className="button is-primary is-light" type="button" onClick={() => navigate(-1)}>Cancel</button>
                </div>
              </div>
            </form>

          </div>
        </div>

      </div>
    </section>
  );
}

export default ContactsPage;
