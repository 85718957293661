interface Props {
  text: string;
}

export function BoldifyText({text}: Props) {
  // Regular expression to find *bold* parts in the text
  const boldRegex = /(\*.*?\*)/g;

  // Split the text into parts based on the boldRegex
  const parts = text.split(boldRegex);

  // Iterate through parts and render them accordingly
  const renderText = parts.map((part, index) => {
    if (part.match(boldRegex)) {
      // If the part is surrounded by *, render it as bold
      return <strong key={index}>{part.slice(1, -1)}</strong>;
    } else {
      // Otherwise, render it as normal text
      return <span key={index}>{part}</span>;
    }
  });

  return <div>{renderText}</div>;
}
