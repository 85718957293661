import { BrowserRouter, Route, Routes } from "react-router-dom";
import VerbSelectionPage from "./VerbSelectionPage";
import VerbConjugationsPage from "./VerbConjugationsPage";
import TenseSelectionPage from "./TenseSelectionPage";
import LandingPage from "./LandingPage";
import ContactsPage from "./ContactsPage";
import NotFoundPage from "./NotFoundPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LandingPage/>} />
        <Route path="/conjugations" element={<VerbConjugationsPage/>} />
        <Route path="/verbs" element={<VerbSelectionPage/>} />
        <Route path="/tenses" element={<TenseSelectionPage/>} />
        <Route path="/contacts" element={<ContactsPage/>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
